// i18next-extract-mark-ns-start terms-of-service

import {Article} from 'components/Article';
import {SEO} from 'components/SEO';
import {graphql} from 'gatsby';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';
import React from 'react';

const Page: React.FC = () => {
  const {t} = useI18next();
  return (
    <Article wide>
      <SEO
        path="legal-notice"
        title={t('Legal Notice')}
        description={t(
          'By using our services and/or signing up for an account, you agree to follow these terms of use and all of its contents.'
        )}
      />
      <Trans parent="h1">
        Legal notice and Conditions of Use of the Website and Applications MONEI Digital Payments
        S.L.
      </Trans>
      <Trans parent="p">
        These Terms of Use regulate the free access and use of the Website called{' '}
        <a href="https://monei.com" rel="noreferrer noopener">
          monei.com
        </a>{' '}
        (hereinafter the "Website") and digital applications (hereinafter the "App" or
        "Applications") owned by MONEI DIGITAL PAYMENTS, S.L., with address at Calle Palestina 1,
        Entreplanta, 29007- Málaga and with VAT number B02660926, registered in the Mercantile
        Registry of Malaga in Volume 5998, L. 4905, F. 54, S. 8, H. MA158775, and with Payment
        Entity Registration granted by the Banco de España Nº6911 (hereinafter "MONEI"). Access
        and/or use of the MONEI Website and/or Applications implies the User's acceptance of these
        Terms of Use.
      </Trans>
      <Trans parent="h2">1. Creating an Account or Profile</Trans>
      <Trans parent="p">
        To use the Services you must be at least 18 years old and register an account with MONEI
        (“Profile” or “Account”).
      </Trans>
      <Trans parent="p">
        You can register one or more Accounts if you have several online merchants with different
        websites or business premises in different locations.
      </Trans>
      <Trans parent="p">
        The registration of Authorized Users will be subject to the rates described in this link
        (“control panel users” section). If you register other Authorized Users they may access your
        Account and use/manage the Services contracted on your behalf or on behalf of the legal
        entity you represent (hereinafter the “Merchant”). Authorized Users where appropriate will
        also be bound by these Terms of Use. We reserve the right to limit the number of Authorized
        Users and to deny the registration of your Authorized Users.
      </Trans>
      <Trans parent="p">
        Authorized Users may have different permissions to access the Account:
      </Trans>
      <ul>
        <Trans parent="li">Administration permission</Trans>
        <Trans parent="li">Read/Write Permission</Trans>
        <Trans parent="li">Permission to read</Trans>
      </ul>
      <Trans parent="p">
        In any case it will be your sole responsibility to determine the Users who will have access
        to the Merchant account and with what permissions and it will also be your responsibility to
        manage permissions and passwords and to unsubscribe where appropriate Authorized Users from
        whom you decide to withdraw the permissions granted with respect to the Account.
      </Trans>
      <Trans parent="p">
        In connection with the registration and use of your Account you will be asked to provide
        your name email address and other information as well as the identification data of the
        Merchant you represent (if any). We will use the information for a variety of purposes
        including to verify your identity and to comply with our legal and regulatory obligations.
        You can learn more about how we use your data in our Privacy Policy. You must provide MONEI
        with complete and accurate information and keep the information in your Account up to date
        when requested. You can inform us of any changes by contacting Customer Service at the
        following email address: support@monei.com. We reserve the right to suspend or cancel your
        Account as well as to limit access to any of the Services if you do not provide complete and
        accurate information (including additional information that may be required to create the
        Profile) or if you do not meet the registration or verification requirements for the
        Account.
      </Trans>
      <Trans parent="h2">2. Use of the Website and Applications: Responsibilities</Trans>
      <Trans parent="p">
        The User is responsible for the access and correct use of the Website and/or the MONEI
        Applications subject to the laws in force in Spain, as well as to the principles of good
        faith, morals and public order, and with the commitment to diligently observe any
        instructions that, in relation to such use and access, may be provided by MONEI in
        accordance with the provisions of these Terms of Use.
      </Trans>
      <Trans parent="p">
        The User is obliged to make reasonable use of the Website and/or the Applications and their
        contents, according to the possibilities and purposes for which it is intended.
      </Trans>
      <Trans parent="p">
        The User is solely responsible for the information, opinions, allusions or content of any
        kind that they may communicate through the Website and/or the MONEI Applications.
      </Trans>
      <Trans parent="p">
        The MONEI Website and Applications, as well as the products and services of that entity, are
        under no circumstances intended for minors. MONEI will not knowingly collect information or
        data from minors. However, due to the free access to the Website and/or the MONEI
        Applications electronically, it is noted that it is the responsibility of parents,
        representatives or legal guardians to take the necessary precautions and measures regarding
        the access of minors to websites and/or to the Applications and their contents that could be
        accessible while browsing by minors.
      </Trans>
      <Trans parent="h2">3. Uses not allowed</Trans>
      <Trans parent="p">
        As a User of the MONEI Website and/or Applications, we inform you that it is PROHIBITED and,
        therefore, its consequences will be your sole responsibility to access or use it for illegal
        or unauthorized purposes, with or without economic purpose. Specifically, by way of example
        and without the following list being absolute, it is prohibited:
      </Trans>
      <ul>
        <Trans parent="li">
          Make illegal use of the Website and/or the Applications, or in any other way that could
          damage, overload or harm the Website itself and/or the Applications.
        </Trans>
        <Trans parent="li">
          Introduce computer viruses, faulty files, or host, store, distribute or share any other
          material or computer program that may cause damage or alteration to the contents, programs
          or systems of the Website and/or Applications.
        </Trans>
        <Trans parent="li">
          Use or resell for unauthorized commercial purposes the contents included in the Website
          and/or Applications without prior authorization from the owner.
        </Trans>
      </ul>
      <Trans parent="p">
        MONEI shall be entitled to adopt the necessary measures in the event of non-compliance with
        the provisions of this clause, at its sole discretion, or at the request of an affected
        third party or competent authority. The adoption of such measures will not entitle you to
        any compensation.
      </Trans>
      <Trans parent="h2">4. Protection of personal data</Trans>
      <Trans parent="p">
        The collection and processing of personal data provided by the User, as well as the exercise
        of the User's rights over such data, will be governed by the{' '}
        <Link to="/privacy-policy">Privacy Policy</Link> of the Website.
      </Trans>
      <Trans parent="h2">
        5. Acquisition of products and services: General conditions of contract
      </Trans>
      <Trans parent="p">
        The products and services offered through the Website and/or the Applications will be
        subject to the corresponding General Contracting Conditions contained in the Framework
        Agreement for Payment Services, together, where appropriate, with the Special Conditions
        that may be established and any other conditions that are necessary for the effective
        contracting of the products and services.
      </Trans>
      <Trans parent="h2">
        6. Opening of a basic profile for contracting technological platform services associated
        with payment services provided by third parties (MONEI PLUS, Merchants in Andorra and
        acquisition services provided by Comercia Global Payments)
      </Trans>
      <Trans parent="p">In the event that the Merchant:</Trans>
      <ul>
        <Trans parent="li">
          Is interested in contracting the MONEI Plus Plan which will allow use of a virtual POS in
          the name of the Merchant provided by a third party
        </Trans>
        <Trans parent="li">
          Is interested in contracting acquisition services with Comercia Global Payments SL Payment
          Entity through the MONEI technological platform or
        </Trans>
        <Trans parent="li">Is an Andorran merchant</Trans>
      </ul>
      <Trans parent="p">
        MONEI makes its services available as a technological payment platform for the purpose of
        managing payment services provided by third parties. It is clarified that in these cases
        MONEI will not provide any payment service but only the technological platform that will
        make it possible to manage all the payment options required by the Merchant on a single
        platform (multiple processors purchasers and payment methods).
      </Trans>
      <Trans parent="p">
        In all these cases the Merchant must only create a basic Profile for which purpose their
        personal data will be required (full name identity document number email address contact
        details and where appropriate the basic data of the Merchant legal entity (company name or
        denomination tax identification number and address). In these cases and since MONEI will act
        only as a provider of technological platform services the Merchant will not sign nor will it
        be applicable to it under any circumstances the MONEI Payment Services Framework Agreement.
      </Trans>
      <Trans parent="p">
        Merchants wishing to contract payment services in Andorra will not have to pay any payment
        to MONEI. In any case they must expressly authorize MONEI to transfer the data from their
        Basic Profile to the Andorran entity that will evaluate and where appropriate approve and
        provide payment services to the Merchant in that jurisdiction. Consequently after the
        creation of the Basic Profile MONEI and the Andorran Merchant will not have any type of
        contractual or other relationship.
      </Trans>
      <Trans parent="p">
        For the technological platform services provided by MONEI to Merchants that contract the
        MONEI Plus Plan MONEI will apply the following rates (“MONEI Plus” section). Billing will be
        weekly and the corresponding amounts will be collected through direct debits from a bank
        account owned by the Merchant whose details must be reported to MONEI.
      </Trans>
      <Trans parent="p">
        On the other hand the technological platform services provided by MONEI to Merchants that
        contract the acquisition services directly with Comercia Global Payments SL Payment Entity
        MONEI will apply the following fees (section “MONEI X: MONEI's dynamic pricing for using the
        platform”). Billing will be weekly and the corresponding amounts will be collected through
        direct debits from a bank account owned by the Merchant whose details must be reported to
        MONEI.
      </Trans>
      <Trans parent="p">
        The Merchant declares to accept and understand that third parties other than MONEI that
        provide them with purchasing services under the MONEI Plus Plan through Comercia Global
        Payments SL Payment Entity or in Andorra will be solely responsible for these services.
        MONEI only assumes responsibility for the technological platform services provided by this
        entity to Merchants that contract the MONEI Plus Plan or to those that access the payment
        services of Comercia Global Payments SL Payment Entity through the MONEI technological
        platform and will not deal with complaints or claims of any kind that are related to payment
        services or other third-party services.
      </Trans>
      <Trans parent="h2">7. Customer Service for complaints and claims</Trans>
      <Trans parent="p">
        The User is informed that MONEI has a Customer Service, as established in Order
        ECO/734/2004, of March 11, on customer service departments and services and the customer
        advocate of financial institutions. You can contact MONEI Customer Service by writing to the
        following address: support@monei.com
      </Trans>
      <Trans parent="p">
        In case of problems we recommend that you first seek a solution by writing to the email
        support@monei.com. If you are unable to resolve the issue or if you are not satisfied with
        the response obtained you can submit a written complaint or complaint to MONEI Customer
        Service as detailed in this link.
      </Trans>
      <Trans parent="h2">8. Exemption from liability</Trans>
      <Trans parent="h3">8.1. For the content provided by Users</Trans>
      <Trans parent="p">
        MONEI is not responsible for the contents, files, information, advertising, opinions,
        concepts and images hosted by the User on the Website and/or in the Applications that are
        contrary to law, morals, good faith and public order or contain any type of computer virus
        or similar software routine.
      </Trans>
      <Trans parent="p">
        MONEI is not responsible for the contents, files, information, advertising, opinions,
        concepts and images that do not depend on the Website and/or the Applications, nor are they
        managed by MONEI.
      </Trans>
      <Trans parent="p">
        MONEI will not assume any responsibility, whether direct or indirect, for the misuse of the
        Website and/or the Applications or their contents by the User, who assumes, in any case,
        under his sole responsibility, the consequences, damages or actions that may result from his
        access or use of the Website and/or the Applications or the contents hosted therein, as well
        as from their reproduction or communication.
      </Trans>
      <Trans parent="h3">8.2. For the operation of the Website and/or Applications</Trans>
      <Trans parent="p">
        MONEI provides its services and contents on an ongoing basis, using all the technical means
        at its disposal to carry out this service satisfactorily.
      </Trans>
      <Trans parent="p">
        MONEI may, when it deems it appropriate, make corrections, improvements or modifications to
        the information contained in the Website and/or in the Applications, in the services, or in
        the contents without giving rise to, or the right to any claim or compensation, or implying
        recognition of any liability.
      </Trans>
      <Trans parent="p">
        MONEI is not responsible for damages of any kind that may arise from the availability and
        technical continuity dependent on third parties in the operation of the Website and/or the
        Applications. In any case, MONEI will carry out all necessary actions to restore its
        services in the event of a technical failure.
      </Trans>
      <Trans parent="p">
        The User is solely and exclusively responsible for identifying and accessing the contents or
        services of the Website and/or Applications.
      </Trans>
      <Trans parent="p">
        MONEI is not responsible for the misuse of the identification and access of users to access
        the contents or services of the Website and/or the Applications that require them and for
        the consequences derived from any nature, as well as for the misuse by users, their loss or
        oblivion, and their misuse by unauthorized third parties.
      </Trans>
      <Trans parent="h2">9. Intellectual and industrial property</Trans>
      <Trans parent="p">
        MONEI, as the author of the collective work that consists of the Website and/or the
        Applications, is the owner of all industrial and intellectual property rights over them.
      </Trans>
      <Trans parent="p">
        Any form of reproduction, distribution, public communication, transformation, making
        available and, in general, any act of exploitation of all or part of the contents (images,
        texts, designs, indexes, forms, etc.) that make up the Website and/or in the Applications,
        as well as of the databases (sui generis right) and the software necessary for the
        visualization or operation of them (including source codes), which does not have the express
        and prior written authorization of MONEI, is prohibited.
      </Trans>
      <Trans parent="p">
        Under no circumstances may the User directly or indirectly exploit or be used commercially,
        in whole or in part, of any of the contents (images, texts, designs, indexes, forms, etc.)
        that make up the Website and/or the Applications without the prior written authorization of
        MONEI.
      </Trans>
      <Trans parent="p">
        All the contents, without exception and in a non-limiting way, that are part of the Website
        and/or the Applications, that is, information, articles, data, texts, logos, brands, badges,
        icons, images, design and image of the Website and/or the Applications (external appearance
        or "look and feel"), video files, audio files, databases, computer applications, are the
        property of MONEI or are exploited under license from third parties who own the intellectual
        and/or industrial property rights of the said contents. These are protected by current
        intellectual and industrial property laws.
      </Trans>
      <Trans parent="h2">10. Changes and modifications to the terms of use</Trans>
      <Trans parent="p">
        MONEI may, at any time, modify these conditions or introduce new terms of use, simply
        communicating it through the Website and/or the Applications.
      </Trans>
      <Trans parent="p">
        In the event that a regulatory action, legal or regulatory measure occurs that, in MONEI's
        reasonable judgment, prohibits, substantially restricts or makes the provision of the
        service commercially unfeasible, MONEI will be entitled to: (i) modify the service or the
        terms and clauses of these Terms of Use in order to adapt to the new situation, (ii) resolve
        the relationship with the User.
      </Trans>
      <Trans parent="h2">11. Average payment period to suppliers</Trans>
      <Trans parent="p">
        In accordance with the provisions of Law 31/2014, of December 3, amending the Capital
        Companies Act for the improvement of corporate governance; as well as Law 15/2010, of July
        5, amending Law 3/2004, of December 29, which establishes measures to combat late payments
        in commercial transactions, it is reported that the average payment period to MONEI
        suppliers is 35 days.
      </Trans>
      <Trans parent="h2">12. Applicable Law and Jurisdiction</Trans>
      <Trans parent="p">
        Current regulations will determine the laws that should govern and the jurisdiction that
        should be known about the relations between MONEI and the User of the Website and/or
        Applications.
      </Trans>
    </Article>
  );
};

export default Page;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "terms-of-service"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
